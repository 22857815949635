import { useEffect, useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, FormHelperText } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Metadata } from '@one/api-models/lib/Metadata/Metadata';
import { MetadataKey } from '@one/api-models/lib/Metadata/MetadataKey';

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from 'styled';

interface EditMetadataDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSave: (metadata: Metadata[]) => void;
  testId: string;
  metadata: Metadata[];
  metadataKeys: MetadataKey[];
  entityId?: string;
}
interface MetadataItem {
  key: string;
  value: string;
}
interface MetadataForm {
  metadata: MetadataItem[];
}

const uniqueKeysTest = {
  name: 'unique-keys',
  message: 'Metadata keys must be unique',
  test: function (this: yup.TestContext, value: any) {
    if (!Array.isArray(value)) return true;
    const keys = value.map((item: any) => item.key).filter((key: string) => key !== '');
    return new Set(keys).size === keys.length;
  },
};

const schema = yup.object().shape({
  metadata: yup
    .array()
    .of(
      yup.object().shape({
        key: yup.string().required('Key is required'),
        value: yup.string().required('Value is required'),
      }),
    )
    .test(uniqueKeysTest),
});

export const EditMetadataDialog = ({
  open,
  testId,
  metadata,
  metadataKeys,
  entityId,
  handleSave,
  handleClose,
}: EditMetadataDialogProps) => {
  const theme = useTheme();
  const formRef = useRef<HTMLFormElement>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MetadataForm>({
    defaultValues: { metadata: metadata.map((item) => ({ key: item.metadataKey, value: item.metadataValue })) },
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'metadata',
  });

  useEffect(() => {
    if (metadata?.length === 0) append({ key: '', value: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: MetadataForm) => {
    handleSave(
      data.metadata?.map((item) => ({
        entityId: entityId || '',
        entityType: 'Order',
        metadataKey: item.key,
        metadataValue: item.value,
      })),
    );
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '496px',
        },
      }}
    >
      <DialogTitle>
        Edit Metadata
        <IconButton onClick={handleClose} size="small" disableRipple data-testid={`${testId}CloseButton`}>
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" ref={formRef}>
          {fields.map((field, index) => (
            <Box key={field.id} sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Controller
                name={`metadata.${index}.key`}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={metadataKeys || []}
                    value={metadataKeys?.find((option) => option.name === field.value)}
                    noOptionsText="No available keys"
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => {
                      field.onChange(value?.name);
                    }}
                    disableClearable
                    size="small"
                    sx={{
                      width: '254px',
                      '& .MuiAutocomplete-popupIndicator': {
                        display: 'none',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Key"
                        error={!!errors.metadata?.[index]?.key}
                        helperText={errors.metadata?.[index]?.key?.message}
                        onChange={(e) => {
                          e.preventDefault();
                        }}
                        inputProps={{
                          ...params.inputProps,
                          'data-testid': `${testId}SelectKeyInput`,
                        }}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name={`metadata.${index}.value`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Value"
                    fullWidth
                    error={!!errors.metadata?.[index]?.value}
                    helperText={errors.metadata?.[index]?.value?.message}
                    inputProps={{
                      'data-testid': `${testId}ValueInput`,
                    }}
                  />
                )}
              />
              <IconButton onClick={() => remove(index)} size="small">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}

          {errors.metadata && <FormHelperText error>{errors.metadata.message}</FormHelperText>}
        </form>
      </DialogContent>

      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button onClick={() => append({ key: '', value: '' })} variant="text" startIcon={<AddIcon />}>
            Add another item
          </Button>
          <Box>
            <Button variant="outlined" onClick={handleClose} data-testid={`${testId}CancelButton`} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                formRef?.current?.requestSubmit();
              }}
              data-testid={`${testId}SaveButton`}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
